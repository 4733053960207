import React from "react"; 
import IndustriesPage from "../../components/IndustriesPage";

const data = {
  heroSection: {
    heading: ['Automotive', ' Manufacturing'], 
    imageUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/automotive-logo',
    content: [
      'Loopr offers solutions to verify that high-mix parts are assembled to specifications and satisfy safety conformance checks.', 
    ]
  }, 
  problemsSection: {
    heading: 'Challenges in automotive assembly', 
    minHeight: '17rem',
    content: [
      {
          title: ['High-mix'], 
          text: 'Manual assembly of high-mix parts according to spec sheet is error prone resulting in expensive product re-calls', 
          imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/document-incorrect-icon.png', 
      },
      {
          title: ['Assembly checks'], 
          text: 'Easy to overlook incorrectly assembled part which can cause serious safety hazards if not caught', 
          imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/wrench-icon.png'
      },
      { 
          title: ['Inconsistent'], 
          text: 'Manual assembly verification is subjective, difficult to standardize and differs from operator to operator', 
          imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/inconsistent-icon.png'
      }
    ]   
  }, 
  contentSection: [
    {
      type: 'light', 
      headerContent: {
        labelImgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/industries-search-icon.png', 
        labelImgDimensions: {
          width: '3.81rem', 
          height: '4.68rem'
        },
        heading: 'Automate assembly verification', 
        description: '', 
        imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/automotive-content-01.png', 
        imgDimensions: {
          width: '43.93rem', 
          height: '23.5rem'
        }
      }, 
      content: [
        {
          title: 'Problem', 
          text: [
            'An automotive manufacturer struggles to consistently verify that pinch-bolts are correctly fastened on steering shafts of every manufactured vehicle.', 
            'Manual quality check can overlook incorrect assembly, lacks a proof of record and results in expensive vehicle re-calls.'
          ]
        }, 
        {
          title: 'Solution', 
          text: [
            "LooprIQ Verify was deployed on a hand-held tablet to consistently and accurately identify whether a pinch-bolt is correctly fastened on a steering shaft.", 
            'After assembling the pinch-bolt, mechanics use LooprIQ Verify to validate correct assembly and save a proof of record.'
          ]
        }, 
        {
          title: 'Outcome', 
          text: [
            {
              primaryText: 'Accountability', 
              description: 'Quality check workflow has more operator accountability and proof of record at time of assembly itself.'
            }, 
            {
              primaryText: 'Lower Re-calls', 
              description: 'Improved quality of assembly results in significantly lower vehicle re-calls and reduced cost of quality.'
            }
          ]
        }
      ]
    }
  ]
}

export default function AutomotivePage() {
  return <IndustriesPage data={data} />
}